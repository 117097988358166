<template>
  <div class="navigation">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <router-link to="/" class="navbar-brand">
        <img
          src="../../../assets/wtxhub_logo.png"
          alt="wtxhub logo"
          class="img-fluid"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <router-link to="/" class="nav-link"
              >Home <span class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-item">
            <!-- <router-link to="/about" class="nav-link"> About</router-link> -->
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Products
            </a>

            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link to="/business" class="dropdown-item">
                Business</router-link
              >
              <div class="dropdown-divider"></div>
              <router-link to="/consumer" class="dropdown-item"
                >Consumer</router-link
              >
            </div>
          </li>
          <li class="nav-item">
            <router-link to="/contactus" class="nav-link"
              >Contact Us</router-link
            >
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.navigation {
  .navbar {
    padding: 0 1rem;
    img {
      width: 70px;
    }

    .nav-item {
      .nav-link {
        color: #fff;
      }
    }
  }
}
</style>
