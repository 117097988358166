<template>
  <div class="home">
    <Carousel />
    <About />
    <Products />
  </div>
</template>

<script>
// @ is an alias to /src
import Carousel from "@/components/Carousel";
import About from "@/components/About";
import Products from "@/components/Products";
// import ContactForm from "@/components/ContactForm";

export default {
  name: "Home",
  components: {
    Carousel,
    About,
    Products
  }
};
</script>
