<template>
  <div id="app">
    <Navigation />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/global/Navigation";
import Footer from "@/components/global/Footer";
export default {
  name: "App",
  components: {
    Navigation,
    Footer
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

#app {
  font-family: "Raleway", sans-serif;
}

h2 {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 3px;
    width: 50px;
    background-color: orange;
  }
}
</style>
